<template>
    <v-container fluid>
        <PageHeaderSlot :isLoading="$store.getters.isLoading"> </PageHeaderSlot>
        <v-row>
            <v-col cols="12">
                <v-form ref="dataForm" @submit.prevent="save">
                    <v-card class="mb-8">
                        <v-card-title tag="h1" class="mb-4">Slide Images</v-card-title>
                        <v-card-text>
                            <ImageUploader 
                                fileInputKey="upload_slide_image"
                                :imgList.sync="formData.slide_image" 
                                acceptFormat="image"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></ImageUploader>
                        </v-card-text>
                    </v-card>

                    <v-card class="mb-8">
                        <v-card-title tag="h1" class="mb-4">About Us Content</v-card-title>
                        <v-card-text>
                            <FormTextarea
                                label="WHO WE ARE"
                                :fieldValue.sync="formData.content"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormTextarea>
                        </v-card-text>
                    </v-card>

                    <v-card class="mb-8">
                        <v-card-title tag="h1" class="mb-4">About InLab Image</v-card-title>
                        <v-card-text>
                            <ImageUploader
                                singleImage
                                fileInputKey="upload_banner_image"
                                :imgList.sync="formData.banner_image" 
                                acceptFormat="image"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></ImageUploader>
                        </v-card-text>
                    </v-card>

                    <v-card class="mb-8">
                        <div class="d-flex">
                            <v-card-title tag="h1" class="mb-4">Why Choose Inlab</v-card-title>
                            <v-btn small depressed color="primary accent-4 ma-4" class="white--text" type="button"
                                :disabled="$store.getters.isLoading" @click="openAddFeatureDialog()">Add</v-btn>
                        </div>

                        <v-card-text class="pa-0">
                            <Datatable 
                                :tableData="featureTableData" 
                                :tableHeaders="featureTableHeaders" 
                                disablePagination
                                disableFooter 
                                disableToolbar 
                                disableShadow 
                                @view-clicked="openAddFeatureDialog($event)"
                                @delete-clicked="handleDeleteFeature($event)"
                            ></Datatable>
                        </v-card-text>
                    </v-card>

                    <v-card class="mb-8">
                        <v-card-title tag="h1" class="mb-4">Why Choose InLab Image</v-card-title>
                        <v-card-text>
                            <ImageUploader 
                                singleImage 
                                fileInputKey="upload_feature_image"
                                :imgList.sync="formData.feature_image" 
                                acceptFormat="image"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></ImageUploader>
                        </v-card-text>
                    </v-card>


                    <v-btn depressed color="primary accent-4" class="white--text" type="submit"
                        :loading="$store.getters.isLoading"> Save </v-btn>
                </v-form>
            </v-col>
        </v-row>

        <FormDialog 
            ref="featureFormDialog" 
            title="Why Choose InLab" 
            hideToolBar 
            :maxWidth="900"
            @submit-clicked="handleFeatureDialogConfirm()" 
            formRef="featureForm"
        >
            <v-row class="ma-0">
                <v-col cols="12">
                    <FormInput 
                        label="Title" 
                        :fieldValue.sync="featureForm.title"
                        required
                    ></FormInput>
                </v-col>
                <v-col cols="12">
                    <FormTextarea 
                        label="Content" 
                        :fieldValue.sync="featureForm.content"
                        required
                    ></FormTextarea>
                </v-col>
                <v-col cols="12">
                    <ImageUploader
                        singleImage
                        fileInputKey="upload_feature_icon"
                        :imgList.sync="featureForm.icon"
                        acceptFormat="imageIcon"
                    ></ImageUploader>
                </v-col>
            </v-row>
        </FormDialog>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';
import FormInput from '@/components/form/FormInput.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import Datatable from '@/components/Datatable.vue';
import FormDialog from '@/components/FormDialog.vue';
import FormTextarea from '@/components/form/FormTextarea.vue';

export default {
    name: 'About',
    components: {
        FormInput,
        ImageUploader,
        Datatable,
        FormDialog,
        FormTextarea
    },
    computed: {
        dispatchUpdateOnChange() {
            return true;
        },
    },
    data: () => ({
        rowCount: 0,
        formData: {
            slide_image: [],
            content: '',
            banner_image: [],
            feature_image: []
        },

        featureTableData: [],
        featureForm: {
            title: '',
            content: '',
            icon: []
        },


        // --- static data
        featureTableHeaders: [
            { text: 'Title', value: 'title' },
            { text: 'Icon', value: 'preview_image_list', width: 150 },
            { text: 'Content', value: 'content' },
            { text: '', value: 'actionViewDelete', width: 240  },
        ],
    }),

    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getAboutData() {
            try {
                const data = await this.$Fetcher.GetAboutData();
                this.$set(this.formData, 'slide_image', data.slide_images);
                this.$set(this.formData, 'content', data.about_content);
                if (this.$validate.DataValid(data.middle_banner_image)) {
                    this.formData.banner_image.push(data.middle_banner_image);
                }
                if (this.$validate.DataValid(data.feature_image)) {
                    this.formData.feature_image.push(data.feature_image);
                }
                if (this.$validate.DataValid(data.feature_list)) {
                    data.feature_list.forEach(item => {
                        this.featureTableData.push({
                            id: this.rowCount,
                            title: item.title,
                            content: item.content,
                            preview_image_list: this.$validate.DataValid(item.icon) ? [item.icon] : [],
                        })

                        this.rowCount++;
                    })
                }
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },

        async openAddFeatureDialog(id) {
            if (this.$store.getters.isLoading) {
                return;
            }

            this.$set(this.featureForm, 'title', '');
            this.$set(this.featureForm, 'content', '');
            this.$set(this.featureForm, 'icon', []);
            await this.$refs['featureFormDialog'].$refs['featureForm'].reset();

            if (this.$validate.DataValid(id)) {
                const target = this.featureTableData.find(el => el.id === id);
                if (target) {
                    this.$refs['featureFormDialog'].setEditId(id);
                    this.$set(this.featureForm, 'title', target.title);
                    this.$set(this.featureForm, 'content', target.content);
                    this.$set(this.featureForm, 'icon', target.preview_image_list);
                }
            }

            this.$refs['featureFormDialog'].show();
        },
        async handleFeatureDialogConfirm() {
            const id = this.$refs['featureFormDialog'].getEditId();
            if (this.$validate.DataValid(id)) {
                const target = this.featureTableData.find(el => el.id === id);
                if (target) {
                    this.$set(target, 'title', this.featureForm.title);
                    this.$set(target, 'content', this.featureForm.content);
                    this.$set(target, 'preview_image_list', this.featureForm.icon);
                    this.$store.dispatch('setDataIsUpdated', true);
                }
            } else {
                this.featureTableData.push({
                    id: this.rowCount,
                    title: this.featureForm.title,
                    content: this.featureForm.content,
                    preview_image_list: this.featureForm.icon
                });
                this.rowCount++;
                this.$store.dispatch('setDataIsUpdated', true);
            }

            this.$refs['featureFormDialog'].hide();
        },
        handleDeleteFeature(id) {
            const targetPos = this.featureTableData.findIndex(el => el.id === id);
            if (targetPos > -1) {
                this.featureTableData.splice(targetPos, 1);
                this.$store.dispatch('setDataIsUpdated', true);
            }
        },

        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);
            const valid = await this.$refs['dataForm'].validate();
            if (!valid) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            const payload = {
                slide_images: this.formData.slide_image,
                about_content: this.formData.content,
                middle_banner_image: this.$validate.DataValid(this.formData.banner_image) ? this.formData.banner_image[0] : '',
                feature_image: this.$validate.DataValid(this.formData.feature_image) ? this.formData.feature_image[0] : '',
                feature_list: this.featureTableData.map(item => {
                    return {
                        title: item.title,
                        content: item.content,
                        icon:  this.$validate.DataValid(item.preview_image_list) ? item.preview_image_list[0] : '',
                    }
                })
            }

            try {
                await this.$Fetcher.SetAboutData(payload);
                this.$store.dispatch('setDataIsUpdated', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'Saved Successfully',
                    type: 'success',
                    refresh: true,
                    redirect: ''
                });

            } catch (err) {
                this.setDialogMessage({
                    title: "Saved Fail",
                    message: err,
                    isError: true,
                    returnLink: null,
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
    },
    async created() {
        this.$store.dispatch('toggleLoadingPage', true);
        this.getAboutData();
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>
  